<template>
  <v-app style="background-color:#f8f9fb" >

    <v-dialog v-model="showProgress" persistent max-width="300">
      <v-card>
        <v-card-title>
          Baixando arquivo...
        </v-card-title>
        <v-card-text>
          <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 365 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>




    <v-container style="background-color:#f8f9fb;" >
      <v-col cols="12">
        <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'mes'" :produtos="tipos_funerarios"        
        @filtrar="filterData" :title_opcao="'Campanha'" 
          ></filtro-component>
      </v-col>
    </v-container>


  <v-container style="background-color:#eee"  
    id="campanha"
    fluid
    tag="section">
   

    <v-row>
    <v-col
    cols="12"
    sm="6"
    lg="4">
    
     <base-material-stats-card elevation="1"
         color="orange" 
         icon="mdi-sale"
         subIcon="mdi-information"
         subIconColor="blue"
         title="Total Vendido (s/ desconto)"
         sub-icon-color="black"
         :valuex="this.bool_01"
         :value="formatarMoeda(this.soma_valor_venda)"
         :sub-text="'Quantidade Vendas de Jazigo: '+  this.qtd_cemiterio"
         :subTextE="'Quantidade Vendas de Plano : ' + this.qtd_planos"
         subIconColorE="blue"
         subIconE="mdi-information"
         />
    
   </v-col>

   <v-col
   cols="12"
    sm="6"
    lg="4">
   
    <base-material-stats-card elevation="1"
        color="cyan"
        icon="mdi-briefcase"
        subIcon="mdi-information"
        subIconColor="blue"
        title="Venda de Plano (s/ desconto)"
        sub-icon-color="black"
        :valuex="this.bool_04"
        :value="formatarMoeda(this.soma_valor_venda_plano)"
        :sub-text="'Quantidade Vendas Novas: '+  (this.qtd_planos - this.qtd_venda_migracao)"
        :subTextE="'Quantidade Vendas Migração : ' + this.qtd_venda_migracao"
        subIconColorE="blue"
        subIconE="mdi-information"

        />
   
  </v-col>

  <v-col
   cols="12"
    sm="6"
    lg="4">
   
    <base-material-stats-card elevation="1"
        color="green"
        icon="mdi-cross"
        subIcon="mdi-information"
        subIconColor="blue"
        title="Venda de Jazigo (s/ desconto)"
        sub-icon-color="black"
        :valuex="this.bool_05"
        :value="formatarMoeda(this.soma_valor_venda_cemiterio)"
        :sub-text="'Quantidade Vendas de Jazigo: '+  this.qtd_cemiterio"
        :subTextE="'  ' "
        subIconColorE="white"
        subIconE="mdi-information"

        />
   
  </v-col>

   
  
      <v-col
      cols="12"
      sm="6"
      lg="4">
     
     <base-material-stats-card elevation="1"
     color="blue"
     icon="mdi-shopping"
     subIcon="mdi-information"
     subIconColor="blue"
     title="Total de Receita (entrada caixa)"
     sub-icon-color="black"
     :valuex="this.bool_02"
     :value="formatarMoeda(this.soma_valor_final)"
     :sub-text="'Quantidade Vendas de Jazigo: '+  this.qtd_cemiterio"
     :subTextE="'Quantidade Vendas de Plano : ' + this.qtd_planos"
     subIconColorE="blue"
     subIconE="mdi-information"
     />


       
      
     </v-col>

     <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card elevation="1"
          color="purple"
          icon="mdi-briefcase-check"
          subIcon="mdi-information"
          subIconColor="blue"
          title="Total Plano + Jazigo (COMBO)"
          sub-icon-color="black"
          :valuex="this.bool_06"
          :value="formatarMoeda(this.soma_valor_venda_combo)"
          :sub-text="'Quantidade Vendas de Jazigo: '+  this.qtd_jazigo_combo"
          :subTextE="'Quantidade Vendas de Plano : ' + this.qtd_jazigo_combo"
          subIconColorE="blue"
          subIconE="mdi-information"
  
          />
     
    </v-col>


     <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card elevation="1"
          color="red"
          icon="mdi-bullhorn-variant"
          subIcon="mdi-information"
          subIconColor="blue"
          title="Total de Desconto Concedido"
          sub-icon-color="black"
          :valuex="this.bool_03"
          :value="formatarMoeda(this.soma_valor_desconto)"
          :sub-text="'Quantidade Vendas de Jazigo: '+  this.qtd_cemiterio"
          :subTextE="'Quantidade Vendas de Plano : ' + this.qtd_planos"
          subIconColorE="blue"
          subIconE="mdi-information"
 
          />
     
    </v-col>


    <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card elevation="1"
          color="red"
          icon="mdi-file-document-remove"
          subIcon="mdi-information"
          subIconColor="blue"
          title="Total de Contratos Cancelados"
          sub-icon-color="black"
          :valuex="this.bool_03"
          :value="formatarMoeda(this.perda_cancelamento)"
          :sub-text="'Valores de Documentos Cancelados'"
          :subTextE="'Total de Contratos : ' + this.total_contratos_perda"
          subIconColorE="blue"
          subIconE="mdi-information"
 
          />
     
    </v-col>

    <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card elevation="1"
          color="#d3a790"
          icon="mdi-currency-usd"
          subIcon="mdi-information"
          subIconColor="blue"
          title="Volume de Inadimplência"
          sub-icon-color="black"
          :valuex="this.bool_03"
          :value="formatarMoeda(this.inadimplencia_acumulda)"
          :sub-text="'Valores de Documentos em Atraso'"
          :subTextE="'Total de Contratos : ' + this.total_contrato_inadimplencia_periodo"
          subIconColorE="blue"
          subIconE="mdi-information"
 
          />
     
    </v-col>


    <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card elevation="1"
          color="cyan"
          icon="mdi-account-wrench-outline"
          subIcon="mdi-information"
          subIconColor="blue"
          title="Sinistralidade"
          sub-icon-color="black"
          :valuex="this.bool_03"
          :value="(this.total_sinistralidade)"
          :sub-text="'Total de Sinistro'"
          :subTextE="' 0% ' "

          subIconColorE="blue"
          subIconE="mdi-information"
 
          />
     
    </v-col>

   

  

  </v-row>
    <v-row>
      
      <v-col
      cols="12"
      sm="6"
      lg="6">

        

       <v-card  color="white" dark style="height: 340px !important;" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Tipo de Campanha %</v-card-title>
        <apexcharts type="pie"   height="250"   :options="generatePieChartOptions(this.gf_3_label, this.gf_3_color)" :series="gf_3_series"></apexcharts>
      </v-card>
     
      </v-col>

      
      <v-col
      cols="12"
      sm="6"
      lg="6">

          <v-card color="white" dark style="height: 340px !important;" elevation="1">
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Receita de Venda por Empresa %</v-card-title>
          <apexcharts type="pie" height="250"   :options="generatePieChartOptions(this.gf_2_label, this.gf_2_color)" :series="gf_2_series"></apexcharts>
           </v-card>
       
      </v-col>

      <v-col
      cols="12"
      sm="6"
      lg="6">

          <v-card color="white" dark style="height: 340px !important;" elevation="1">
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Renegociação</v-card-title>
          <apexcharts type="bar" height="250"   :options="generateBarChartOptions(this.gf_3_label, this.gf_3_color)" :series="gf_4_series"></apexcharts>
           </v-card>
       
      </v-col>

      <v-col
      cols="12"
      sm="6"
      lg="6">

          <v-card color="white" dark style="height: 340px !important;" elevation="1">
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Motivos de Cancelamento</v-card-title>
          <apexcharts type="pie" height="250"   :options="generatePieChartOptions(this.gf_5_label, this.gf_5_color)" :series="gf_5_series"></apexcharts>
           </v-card>
       
      </v-col>



 
      <v-col
       cols="12">

          <base-material-card
          icon="mdi-sale"
          title="Vendas"
          color="green" style="margin-top:20px !important; padding-top:10px !important"
          class="px-5 py-0"
            > 

                <v-data-table
                :headers="headers"
                :items="vendas"
                :items-per-page="5" 
                class="elevation-0">
        
                          <template v-slot:item="row">
                            
                            <tr :class="{'gray-row': row.index % 2 !== 0}">
                              <td style="width: 10%;font-size:14px;">{{row.item.contrato}} </td>
                              <td style="width:10%;font-size:14px;">
                               <v-chip 
                               :color="getStatus(row.item.situacao)"
                               text-color="white">
                               {{getStatusD(row.item.situacao)}}                 
                                   
                               </v-chip>  
                             </td>                              
                             <td style="width: 30%;font-size:14px;">{{row.item.plano}} </td>
                              <td style="width:12%;font-size:14px;">
                                <v-chip 
                                :color="getStatusE(row.item.empresa)"
                                text-color="white">
                                    {{row.item.empresa}}                    
                                    
                                </v-chip>  
                              </td>
                              <td style="width: 12%;font-size:14px;">
                                
                                {{
                                  parseFloat(row.item.valorVenda).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  })
                                }}                          
                              
                              </td>
                              <td style="width: 12%;font-size:14px;">
                              
                                {{
                                  parseFloat(row.item.percentualDesconto * 10).toLocaleString('pt-BR', {
                                    style: 'percent',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                  })
                                }} 
                              
                              </td>
                              <td style="width: 12%;font-size:14px;">
                              
                                {{
                                  parseFloat(row.item.valorFinal).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  })
                                }}
                              
                              </td>

                              
                            </tr>
                        </template>
                </v-data-table>


      <br/>
  
      <div class="d-flex justify-end" style="color:green; font-size: 28px; margin-right: 12px;">
        <span @click="download_xls" style="cursor: pointer;"><i class="fas fa-file-excel"></i></span>
      </div>

            </base-material-card>
     
      </v-col>

      <v-col
      cols="12">
      
         <base-material-card
         icon="mdi-alert"
         title="Vendas com Incoformidades"
         color="red" style="margin-top:20px !important; padding-top:10px !important"
         class="px-5 py-0"
           > 

               <v-data-table
               :headers="headers"
               :items="vendas_incoforme"
               :items-per-page="5" 
               class="elevation-0">
       
                         <template v-slot:item="row">
                           
                           <tr :class="{'gray-row': row.index % 2 !== 0}">
                             <td style="width: 10%;font-size:14px;">{{row.item.contrato}} </td>
                             <td style="width:10%;font-size:14px;">
                               <v-chip 
                               :color="getStatus(row.item.situacao)"
                               text-color="white">
                               {{getStatusD(row.item.situacao)}}                 
                                   
                               </v-chip>  
                             </td>  
                             <td style="width: 30%;font-size:14px;">{{row.item.plano}} </td>
                             <td style="width:12%;font-size:14px;">
                               <v-chip 
                               :color="getStatusE(row.item.empresa)"
                               text-color="white">
                                   {{row.item.empresa}}                    
                                   
                               </v-chip>  
                             </td>
                             <td style="width: 12%;font-size:14px;">
                               
                               {{
                                 parseFloat(row.item.valorVenda).toLocaleString('pt-BR', {
                                   style: 'currency',
                                   currency: 'BRL'
                                 })
                               }}                          
                             
                             </td>
                             <td style="width: 12%;font-size:14px;">
                             
                               {{
                                 parseFloat(row.item.percentualDesconto * 10).toLocaleString('pt-BR', {
                                   style: 'percent',
                                   minimumFractionDigits: 0,
                                   maximumFractionDigits: 0
                                 })
                               }} 
                             
                             </td>
                             <td style="width: 12%;font-size:14px;">
                             
                               {{
                                 parseFloat(row.item.valorFinal).toLocaleString('pt-BR', {
                                   style: 'currency',
                                   currency: 'BRL'
                                 })
                               }}
                             
                             </td>

                             
                           </tr>
                       </template>
               </v-data-table>

               <br/>
  
      <div class="d-flex justify-end" style="color:green; font-size: 28px; margin-right: 12px;">
        <span @click="download_xls_i" style="cursor: pointer;"><i class="fas fa-file-excel"></i></span>
      </div>


           </base-material-card>
    
     </v-col>
      

    </v-row>


  </v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto
  import Campanha from '../../services/campanha' 

  
  import { ContentLoader } from 'vue-content-loader'

  export default {
    name: 'Campanha',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,
    },

    created() {
      this.selectedStartDate = this.getFirstDayOfMonth(),
      this.selectedEndDate =  this.formatDateBR(new Date())
    },

    mounted() {
     this.carregarLoad(),
     this.selectedCompany = "Todas",
     this.dashboard();
     this.gf01();
     this.gf02();
     this.gf03();
     this.gf05();
    },
   

    data () {
      return {


        headers: [
          { text: 'Contrato', value: 'contrato' },
          { text: 'Situação', value: 'situacao' },
          { text: 'Plano', value: 'plano' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Valor Venda', value: 'valorVenda' },
          { text: 'Desconto (%)', value: 'valorDesconto' },
          { text: 'Valor Pago', value: 'valorFinal' },

        ],

        vendas: [],
        vendas_incoforme: [],
        showProgress: false,
        gf_3_label: [],
        gf_3_series: [{ data: []}],
        
        gf_4_series: [{ data: []}],

        gf_3_color:  ['#FFA533', '#0000CD', '#9B59B6', '#2ECC71', '#1ABC9C', '#8B0000'], 
        situacao_promo:true,
        situacao_promo_json:"",

        gf_2_label: [],
        gf_2_series: [{ data: []}],
        gf_2_color:  ['#0000FF', '#FFC654', '#8B0000', '#FF8C00', '#2F4F4F', '#A020F0'], 
        recebimento_empresa:true,
        recebimento_empresa_json:"",

        gf_5_label: [],
        gf_5_series: [{ data: []}],
        gf_5_color:  ['#FFA533', '#0000CD', '#9B59B6', '#2ECC71', '#1ABC9C', '#8B0000'], 



        tipos_funerarios: [
                "Todos",
               
              ],
  

        lisa_cancelados_total_json:"",

         bool_01 : true,
         bool_02 : true,
         bool_03 : true,
         bool_04 : true,
         bool_05 : true,
         bool_06 : true,

         soma_valor_venda:0,
         soma_valor_final:0,
         soma_valor_desconto:0,
         qtd_total:0,
         qtd_planos:0,
         qtd_cemiterio:0,
         qtd_venda_migracao: 0,
         qtd_jazigo_combo: 0,
         soma_valor_venda_combo: 0,
         soma_valor_venda_plano:0,
         soma_valor_venda_cemiterio:0,
         total_contrato_inadimplencia_periodo: 0,
         load_gf1: true,
         load_gf2: false,

         totais_esperado_reneg: 0,
         totais_recebido_reneg: 0,
         total_contratos_perda: 0,
         inadimplencia_acumulda:0,
         perda_cancelamento:0,
         total_sinistralidade:0,

          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Campanha 50 Anos",
          selectMode: "",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: ["Todas","Campanha 50 Anos"],
          modos: ["Venda"],
          categoria: [],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos", "Planos", "Planos Florais", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos", "Serviços Cemiteriais"],


          gf_1_series: [
                      {
                        name: "Memorial Parque Maceió",
                        data: [],
                        color: '#008000',
                      },
                      {
                        name: "Previda",
                        data: [],
                        color: '#0000FF',
                      },
                      {
                        name: "Parque do Agreste",
                        data: [],
                        color: '#808080',
                      },
                      {
                        name: "Cemiterio Previda",
                        data: [],
                        color: '#800080',
                      },
                        {
                        name: "Parque das Flores",
                        data: [],
                        color: '#FF8C00',
                      },
                    ],
            
          lista_valores_mes_gf: {
                      valores: [],
           },
      
      }
    },

   
    methods: {
      chartOptions: {
            chart: {
              height: 390,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                  margin: 5,
                  size: '30%',
                  background: 'transparent',
                  image: undefined,
                },
                dataLabels: {
                  name: {
                    show: false,
                  },
                  value: {
                    show: false,
                  }
                }
              }
            },
            colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
            legend: {
              show: true,
              floating: true,
              fontSize: '16px',
              position: 'left',
              offsetX: 160,
              offsetY: 15,
              labels: {
                useSeriesColors: true,
              },
              markers: {
                size: 0
              },
              formatter: function(seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
              },
              itemMargin: {
                vertical: 3
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                    show: false
                }
              }
            }]
          },
      
          

          download_xls() {
             
              
             this.showProgress = true;

             
             const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
             const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
             const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

             if (diferencaEmDias > 60 && diferencaEmDias < 1) {
               this.isLoading = false;
               this.showFilter = true;
               this.showProgress = false;
               return 
             }
             Campanha.lista_campanha_xls(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao)
             .then(response => {
                       if (response.status === 200) {
                        
                               const fileName = `campanha_${Date.now()}.xlsx`;
                               const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                               const fileLink = document.createElement('a')
                               fileLink.href = fileURL
                               fileLink.setAttribute('download', fileName)
                               document.body.appendChild(fileLink)
                               fileLink.click()
                            
                       }
                     })
                     .catch(error => {
                       // Lida com erros
                       console.error('Erro ao baixar o arquivo Excel:', error);
                     }) .finally(() => {
                       // Após o término do download, oculte o diálogo de progresso
                       this.showProgress = false;
                     });
           },

           download_xls_i() {
             
              
             this.showProgress = true;

             
             const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
             const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
             const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

             if (diferencaEmDias > 60 && diferencaEmDias < 1) {
               this.isLoading = false;
               this.showFilter = true;
               this.showProgress = false;
               return 
             }
             Campanha.lista_campanha_incoforme_xls(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao)
             .then(response => {
                       if (response.status === 200) {
                        
                               const fileName = `campanha_${Date.now()}.xlsx`;
                               const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                               const fileLink = document.createElement('a')
                               fileLink.href = fileURL
                               fileLink.setAttribute('download', fileName)
                               document.body.appendChild(fileLink)
                               fileLink.click()
                            
                       }
                     })
                     .catch(error => {
                       // Lida com erros
                       console.error('Erro ao baixar o arquivo Excel:', error);
                     }) .finally(() => {
                       // Após o término do download, oculte o diálogo de progresso
                       this.showProgress = false;
                     });
           },

           generatePieChartOptions(labels, colors) {
              return {

                chart: {
                  width: 440, // Largura fixa
                  height: 380, // Altura fixa
                                  type: 'pie',
                                },
                                labels: labels,
                              
                  
                  plotOptions: {
                    pie: {
                      dataLabels: {
                        offset: -10
                      }
                    }
                  },

                                colors: colors, // Defina as cores desejadas aqui
                                dataLabels: {
                    formatter(val, opts) {
                      const name = opts.w.globals.labels[opts.seriesIndex]
                      return [val.toFixed(1) + '%']
                    }
                  },
                                responsive: [{
                                  breakpoint: 400,
                                  options: {
                                    chart: {
                                      width: 500
                                    },
                                    legend: {
                                      position: 'footer'
                                    }
                                  }
                                }]

                      
                }

              
            },


            generateBarChartOptions(labels, colors) {
                return {
                  chart: {
                    type: 'bar',
                    width: 500,
                    height: 280,
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: '45%',
                      distributed: true,
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    formatter(val) {
                      return val.toFixed(1);
                    },
                  },
                  colors: colors,
                  xaxis: {
                    categories: ['Esperado', 'Recebido'],
                  },
                  legend: {
                    show: true,
                  },
                  responsive: [
                    {
                      breakpoint: 400,
                      options: {
                        chart: {
                          width: 500,
                        },
                        legend: {
                          position: 'footer',
                        },
                      },
                    },
                  ],
                };
              },


      

      filterData(valores) {
         this.isLoading = true;
         let nova_consulta = false;

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;

          this.bool_01 = true;
          this.bool_02 = true;
          this.bool_03 = true;
          this.bool_04 = true;
          this.bool_05 = true;
          this.bool_06 = true;
        
          //this.gf01();
          this.dashboard();
          this.gf01();
          this.gf02();
          this.gf03();
          this.gf05();
      },

      filtrarTotalGraficos(lista, adesao, tipo, empresa, campoLabel, campoSerie, gf) {
         let totalAcumulado = 0;
 
           const newLabels = [];
           const newSeries = [];
 
         
           for (const item of lista) {
          
                const index = newLabels.indexOf(item[campoLabel]);

                if (index !== -1) {
                  newSeries[index] += 1;
                } else {
                  newLabels.push(item[campoLabel]);
                  newSeries.push(1);
                }

                if(gf == 5){
            console.log( index);
            console.log( campoLabel);
            console.log( item[campoLabel]);

            console.log( newSeries);

            
            }
              
            }


             if(gf == 3){

               this.gf_3_label = newLabels;
               this.gf_3_series = newSeries;
               this.situacao_promo = false;

             }else if(gf == 2){

                this.gf_2_label = newLabels;
                this.gf_2_series = newSeries;
                this.recebimento_empresa = false;

                }else if(gf == 5){

                  this.gf_5_label = newLabels;
                  this.gf_5_series = newSeries;
                //  this.lisa_cancelados_total_json = false;

                  }
                  
                                

             
       },

       async gf01() {
          try {
            const response = await Campanha.total_venda_campanha_grafico(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate),  this.selectedCompany, this.selectedType, "Todos", "Todas");
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              // Extrair os dados da resposta da API
              this.situacao_promo_json = response.data;
              this.filtrarTotalGraficos(this.situacao_promo_json, "Todos", "Todos", this.selectedCompany, "label", "qtd", "3");                      


            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            }
        },

        async gf02() {
          try {
            const response = await Campanha.total_venda_campanha_empresa_grafico(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate),  this.selectedCompany, this.selectedType, "Todos", "Todas");
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              // Extrair os dados da resposta da API
              this.recebimento_empresa_json = response.data;
              this.filtrarTotalGraficos(this.recebimento_empresa_json, "Todos", "Todos", this.selectedCompany, "label", "qtd", "2");                      


            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            }
        },


        async gf05() {
          try {
            const response = await Campanha.lista_perda_cancelamento(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate),  this.selectedCompany, this.selectedType, "Todos", "Todas");
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              // Extrair os dados da resposta da API
              this.lisa_cancelados_total_json = response.data;
              this.filtrarTotalGraficos(this.lisa_cancelados_total_json, "Todos", "Todos", this.selectedCompany, "label", "qtd", "5");                      


            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            }
        },

        async gf03() {


          const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
            const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 40 && diferencaEmDias < 1) {
          
                        return 
                      }


            try {
              this.totais_esperado_reneg  = await Campanha.totais_esperado_reneg(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType)
                .then(response => {
                  if (response.status === 200) {
                    return response.data || 0; // Use 0 ou um valor padrão adequado
                  }
                })
                .catch(e => {
                  console.error('Erro ao obter totais esperados:', e);
                  return 0; // Ou qualquer valor padrão que você deseje atribuir em caso de erro
                });
               
              this.totais_recebido_reneg  = await Campanha.totais_recebido_reneg(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType)
                .then(response => {
                  if (response.status === 200) {
                    return response.data || 0; // Use 0 ou um valor padrão adequado
                  }
                })
                .catch(e => {
                  console.error('Erro ao obter totais recebidos:', e);
                  return 0; // Ou qualquer valor padrão que você deseje atribuir em caso de erro
                });

              // Estrutura final
              this.gf_4_series = [
                { data: [this.totais_esperado_reneg, this.totais_recebido_reneg] } // Ou qualquer valor padrão que você deseje atribuir na primeira posição
              ];
            } catch (error) {
              // Lidar com erros, se necessário
            

            } finally {
            }
          },

        
     
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },



      carregarLoad(){

         this.bool_01 = true,
         this.bool_02 = true,
         this.bool_03 = true

      },

      async dashboard() {




              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 40 && diferencaEmDias < 1) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        return 
                      }

                      Campanha.totais_vendas_campanha(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", "Todas").then(response => {
                          if(response.status === 200){
                            this.soma_valor_final = response.data[0].soma_valor_final;
                            this.qtd_jazigo_combo = response.data[0].qtd_jazigo_combo;                            
                            this.soma_valor_desconto = response.data[0].soma_valor_desconto;
                            this.soma_valor_venda = response.data[0].soma_valor_venda;
                            this.soma_valor_venda_cemiterio = response.data[0].soma_valor_venda_cemiterio;
                            this.soma_valor_venda_plano = response.data[0].soma_valor_venda_plano;
                            this.soma_valor_venda_combo = response.data[0].soma_valor_venda_combo;

                            this.qtd_total = response.data[0].qtd_total;
                            this.qtd_planos = response.data[0].qtd_planos;
                            this.qtd_cemiterio = response.data[0].qtd_cemiterio;
                            this.qtd_venda_migracao = response.data[0].qtd_venda_migracao;

                          } 


                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_01 = false,
                          this.bool_02 = false,
                          this.bool_03 = false
                          this.bool_04 = false
                          this.bool_05 = false
                          this.bool_06 = false
                      });

                       Campanha.lista_venda_campanha(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", "Todas").then(response => {
                          if(response.status === 200){
                            this.vendas = response.data;
                          } 


                        }).catch(e => {    
                        }).finally(() => {
                         
                      });

                      Campanha.lista_venda_campanha_incoforme(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", "Todas").then(response => {
                          if(response.status === 200){
                            this.vendas_incoforme = response.data;

                         } 

                        }).catch(e => {    
                        }).finally(() => {
                         
                      })   


                      Campanha.total_inadimplencia_periodo(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", "Todas").then(response => {

                        if(response.status === 200){
                          this.inadimplencia_acumulda = response.data;                                
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_03 = false;
                        });


                  Campanha.total_contratos_perda(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", "Todas").then(response => {

                      if(response.status === 200){
                        this.total_contratos_perda = response.data;                                
                      } 

                      }).catch(e => {    
                      }).finally(() => {
                      });


                    Campanha.total_contrato_inadimplencia_periodo(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", "Todas").then(response => {

                      if(response.status === 200){
                        this.total_contrato_inadimplencia_periodo = response.data;                                
                      } 

                      }).catch(e => {    
                      }).finally(() => {
                      });
                        

                      


                      Campanha.total_perda_cancelamento(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", "Todas").then(response => {

                      if(response.status === 200){
                        this.perda_cancelamento = response.data;                                
                      } 

                      }).catch(e => {    
                      }).finally(() => {
                      });


                      Campanha.total_sinistro(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType).then(response => {

                      if(response.status === 200){
                        this.total_sinistralidade = response.data;                                
                      } 

                      }).catch(e => {    
                      }).finally(() => {
                      });
                      
                    
                      
      },

      formatarMoeda(valor) {

        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

    getStatusE (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
        },

        getStatus (status) {
            switch (status) {
            case '0': return 'red darken-2'
            case '1' : return 'green lighten-1'
              default: 'success'
            }
        },

        getStatusD (status) {
            switch (status) {
            case '0': return 'Cancelado'
            case '1' : return 'Ativo'
              default: 'success'
            }
        },


     


      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


        clear () {
          this.$refs.form.reset();
        },

        

        generateLineChartOptions(altura, vlMax) {

                  
                return {
                    chart: {
                        height: altura,
                        type: 'line',
                        dropShadow: {
                          enabled: true,
                          color: '#000',
                          top: 18,
                          left: 7,
                          blur: 10,
                          opacity: 0.2
                        },
                        toolbar: {
                          show: true
                        }
                      },
                      dataLabels: {
                        enabled: true,
                        enabledOnSeries: [0, 1, 2, 3, 4], // Índices das séries às quais aplicar rótulos
                        style: {
                          fontSize: '12px',
                          colors: ['red']
                        },
                        formatter: formatMoeda // Use a função de formatação personalizada para rótulos de eixo y

                      },
                   
                      stroke: {
                        curve: 'smooth'
                      },
                      title: {
                       // text: 'Average High & Low Temperature',
                        align: 'left'
                      },
                      grid: {
                        borderColor: '#e7e7e7',
                        row: {
                          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                          opacity: 0.5
                        },
                      },
                      markers: {
                        size: 1
                      },
                      xaxis: {
                        categories: this.categoria,
                        title: {
                          text: 'MESES'
                        }
                      },
                      yaxis: {
                        title: {
                          text: ''
                        },
                        min: 0,
                        max: vlMax,
                        labels: {
                              formatter: formatMoeda // Use a função de formatação personalizada para rótulos de eixo y
                          }
                      },
                      tooltip: {
                          y: {
                              formatter: formatMoeda // Use a função de formatação personalizada para rótulos dos pontos no gráfico
                          },
                          
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                      },
                   
                };

           
              }

     
    },
  }

   
  function formatMoeda(value) {
                      // Use o método toLocaleString com a opção style: 'currency' para formatar como moeda

                      if (value === undefined || value === null) {
                          return 'R$ 0,00'; // Ou você pode retornar 0 como número, se preferir.
                        }

                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                      //return 0
                      //return value
                  }

  function getMonthName(monthNumber) {
    const monthNames = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
        return monthNames[monthNumber - 1];
    } else {
        return ""; // Retorna uma string vazia para números de mês inválidos
    }
}

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }

  function formatMonthName(month) {
    const monthNames = [
        "JAN", "FEV", "MAR", "ABR", "MAI", "JUN",
        "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
    ];

    if (month >= 1 && month <= 12) {
        return monthNames[month - 1];
    } else {
        return ""; // Retorna uma string vazia para números de mês inválidos
    }
  }


</script>
